import Vue from 'vue';
import 'vue-material-design-icons/styles.css';
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';
import VueVideoPlayer from 'vue-video-player';
import ActionButton from '@/components/common/ActionButton.vue';
// require videojs style
import 'video.js/dist/video-js.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/fonts/bootstrap-icons/font/bootstrap-icons.css';
import './assets/tue/tue-theme-default.css';
import './assets/tue/tue.css';
import './assets/tue/tue-layout-default.css';
import './styles/site.scss';
// import './styles/app.css';

import i18n from './i18n'
import App from './App.vue';
// js
import 'bootstrap/dist/js/bootstrap';
import router from './router';
import store from './store';
Vue.use(VueVideoPlayer);


import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

Vue.config.productionTip = false

// global component
// https://www.npmjs.com/package/vue-material-design-icons
Vue.component('menu-icon', MenuIcon);
Vue.component('close-circle-icon', CloseCircleIcon);

// Event bus
export const EventBus = new Vue();

// Main app
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

Vue.component('ActionButton', ActionButton); 
// global values
document.title = 'Progenics';
