import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store';
import { EventBus } from '@/main';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/members',
    component: () => import('@/views/Members/MembersMain.vue'),
    children: [
      {
        name: 'MembersHome',
        path: '/members/home',
        component: () => {
          return import('@/views/Members/MembersHome.vue');
        },
      },
      {
        name: 'EventAdmin',
        path: '/members/Events',
        component: () => { 
          return import('@/views/Members/Events.vue');
        },
      },
      {
        name: 'Event-detail',
        path: 'Events/detail/:id?',
        component: () =>  {
          return import('@/views/Members/EventDetail.vue');
        }
      },
      {
        name: 'Event-detailView',
        path: 'Events/detailView/:id?',
        component: () => {
          return import('@/views/Members/EventDetailView.vue');
        }
      },
      {
        name: 'BlogAdmin',
        path: '/members/Blogs',
        component: () => { 
          return import('@/views/Members/Blogs.vue');
        },
      },
      {
        name: 'Blog-detail',
        path: 'Blogs/detail/:id?',
        component: () =>  {
          return import('@/views/Members/BlogDetail.vue');
        }
      },
      {
        name: 'Blog-detailView',
        path: 'Blogs/detailView/:id?',
        component: () => {
          return import('@/views/Members/BlogDetailView.vue');
        }
      },
      {
        name: 'ProfileAdmin',
        path: 'Profiles',
        component: () => { 
          return import('@/views/Members/Profiles.vue');
        },
      },
      {
        name: 'Profile-detail',
        path: 'Profiles/detail/:id?',
        component: () =>  {
          return import('@/views/Members/ProfileDetail.vue');
        }
      },
      {
        name: 'Profile-detailView',
        path: 'Profiles/detailView/:id?',
        component: () => {
          return import('@/views/Members/ProfileDetailView.vue');
        }
      },     
    ],
    beforeEnter: (to, from, next) => {
      if(!store.getters.user || new Date() > store.getters.user.expirationTime) {
        next({ name: 'Login', query: { ReturnURL: to.path } });
      } else {
        next();
      }
    }
  },
  {
    path: '/login',
    component: () => import('@/views/login/LoginMain.vue'),
    children: [
      {
        name: 'Login',
        path: '',
        component: () => {
          return import('@/views/login/Login.vue');
        },
      },
      {
        name: 'LoginForgotPassword',
        path: '/login/forgotpassword',
        component: () => {
          return import('@/views/login/LoginForgotPassword.vue');
        },
      },
      {
        name: 'LoginResetPassword',
        path: '/login/reset',
        component: () => {
          return import('@/views/login/LoginResetPassword.vue');
        },
      },
      {
        name: 'Register',
        path: '/register',
        component: () => {
          return import('@/views/login/Register.vue');
        },
      },
    ]
  },
  {
    path: '/',
    component: () => { document.title = 'Progenics'; return import('@/views/Main.vue'); },
    children: [
      {
        name: 'Home',
        path: '',
        component: () => {
          return import('@/views/Main/Home.vue');
        },
      },
      {
        name: 'AboutUs',
        path: '/AboutUs/Index',
        component: () => {
          return import('@/views/Main/AboutUs.vue');
        },
      },
      {
        name: 'WhyProgenics',
        path: '/AboutUs/WhyProgenics',
        component: () => {
          return import('@/views/Main/AboutUs/WhyProgenics.vue');
        },
      },
      {
        name: 'OurLab',
        path: '/AboutUs/OurLab',
        component: () => {
          return import('@/views/Main/AboutUs/OurLab.vue');
        },
      },
      {
        name: 'OurClinic',
        path: '/AboutUs/OurClinic',
        component: () => {
          return import('@/views/Main/AboutUs/OurClinic.vue');
        },
      },
      {
        name: 'OurTeam',
        path: '/AboutUs/OurTeam',
        component: () => {
          return import('@/views/Main/AboutUs/OurTeam.vue');
        },
      },
      {
        name: 'Partnership',
        path: '/AboutUs/HospitalPartnership',
        component: () => {
          return import('@/views/Main/AboutUs/Partnership.vue');
        },
      },
      {
        name: 'Foundation',
        path: '/AboutUs/ProgenicsFoundation',
        component: () => {
          return import('@/views/Main/AboutUs/Foundation.vue');
        },
      },
      {
        name: 'Services',
        path: '/Services',
        component: () => {
          return import('@/views/Main/Services.vue');
        },
      },
      {
        name: 'StemCellingBank',
        path: '/Services/StemCellingBank',
        component: () => {
          return import('@/views/Main/Services/StemCellingBank.vue');
        },
      },
      {
        name: 'VirtualConsultation',
        path: '/Services/FreeVirtualConsultation',
        component: () => {
          return import('@/views/Main/Services/VirtualConsultation.vue');
        },
      },
      // {
      //   name: 'LongevityServices',
      //   path: '/Services/LongevityServices',
      //   component: () => {
      //     return import('@/views/Main/Services/LongevityServices.vue');
      //   },
      // },
      
      {
        name: 'Science',
        path: '/Science',
        component: () => {
          return import('@/views/Main/Science.vue');
        },
      },
      {
        name: 'Enroll',
        path: '/Enroll',
        component: () => {
          return import('@/views/Main/Enroll.vue');
        },
      },
      {
        name: 'WhyStore',
        path: '/Science/WhyStoreCordBlood',
        component: () => {
          return import('@/views/Main/Science/WhyStore.vue');
        },
      },
      {
        name: 'TreatableDiseases',
        path: '/Science/TreatableDiseases',
        component: () => {
          return import('@/views/Main/Science/TreatableDiseases.vue');
        },
      },
      {
        name: 'Breakthroughs',
        path: '/Science/RecentBreakthroughs',
        component: () => {
          return import('@/views/Main/Science/Breakthroughs.vue');
        },
      },
      {
        name: 'HowItWorks',
        path: '/HowItWorks',
        component: () => {
          return import('@/views/Main/HowItWorks.vue');
        },
      },
      {
        name: 'Procedures',
        path: '/HowItWorks/Procedures',
        component: () => {
          return import('@/views/Main/HowItWorks/Procedures.vue');
        },
      },
      {
        name: 'Delivery',
        path: '/HowItWorks/DeliveryDay',
        component: () => {
          return import('@/views/Main/HowItWorks/Delivery.vue');
        },
      },
      {
        name: 'CollectionKit',
        path: '/HowItWorks/CollectionKit',
        component: () => {
          return import('@/views/Main/HowItWorks/CollectionKit.vue');
        },
      },
      {
        name: 'FAQ',
        path: '/HowItWorks/FAQs',
        component: () => {
          return import('@/views/Main/HowItWorks/FAQ.vue');
        },
      },
      {
        name: 'Pricing',
        path: '/Pricing',
        component: () =>  {return import('@/views/Main/Pricing/CordBloodPricing.vue');}
      },
       
      {
          name: 'Pricing',
          path: '/Pricing',
          component: () => {
              return import('@/views/Main/Pricing/CordBloodPricing.vue');
            },
      },    
      {
        name: 'Contact',
        path: '/Contact',
        component: () => {
          return import('@/views/Main/Contact.vue');
        },
      },
      {
        name: 'Blogs',
        path: '/Blogs',
        component: () => {
          return import('@/views/Main/Blog.vue');
        },
      },
      {
        name: 'Blog',
        path: '/Blog/:id?',
        component: () => {
          return import('@/views/Blogs/BlogTemplate.vue');
        },
      },
      {
        name: 'User',
        path: '/User/:id?',
        component: () => {
          return import('@/views/Blogs/UserTemplate.vue');
        },
      },
      {
        name: 'Enroll',
        path: '/Enroll',
        component: () => {
          return import('@/views/Main/Enroll.vue');
        },
      },
    ]
  },
  {
    path : '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    window.scrollTo(0,0);
    // return desired position
  }
})

import axios from 'axios';

let initialRefresh = false;
router.beforeEach(async (to, from, next) => {
  // Close any open menu
  EventBus.$emit('closeNav');

  // JWT token stuff
  if (!initialRefresh) {
    initialRefresh = true;

    try {
      await store.dispatch('refreshAccessToken');
      next();
    } catch (error) {
      next(/*location*/);
    } finally {
      store.dispatch('setJwtSilentRefresh');
    }

    return;
  }

  next();
});


export default router