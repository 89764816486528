import Vue from 'vue'
import Vuex, {
  ActionContext,
  Store,
} from 'vuex'
import jwtDecode from 'jwt-decode';
import axios from 'axios';

Vue.use(Vuex);

type DecodedJwt = {
  sub: string;
  username: string;
  role?: string | string[];
  nbf: number;
  exp: number;
  iat: number;
}

export class User {
  public readonly id: number;
  public readonly username: string;
  public readonly roles: string[];
  public readonly notBeforeTime: Date;
  public readonly expirationTime: Date;
  public readonly issuedAtTime: Date;

  constructor(accessToken: string) {
    const decodedJwt = jwtDecode(accessToken) as DecodedJwt;

    this.id = Number(decodedJwt.sub);
    this.username = decodedJwt.username;
    this.roles = Array.isArray(decodedJwt.role) ? decodedJwt.role
      : typeof decodedJwt.role === 'string' ? [ decodedJwt.role ]
      : [];
    this.notBeforeTime = new Date(decodedJwt.nbf * 1000);
    this.expirationTime = new Date(decodedJwt.exp * 1000);
    this.issuedAtTime = new Date(decodedJwt.iat * 1000);
  }
}

type State = {
  accessToken: string;
  user: User | null;
  silentRefreshTimeoutId: number | null;
  programLanding: string;
}

function setAccessTokenCommon(
  this: Store<State>,
  context: ActionContext<State, State>,
  accessToken: string
) {
  context.commit('SET_ACCESS_TOKEN', accessToken);
  context.commit('SET_USER', accessToken !== '' ? new User(accessToken) : null);
}

async function refreshAccessToken(
  this: Store<State>,
  context: ActionContext<State, State>
) {
  type Response = {
    accessToken: string;
  };

  const response = await axios.post<Response>(
    `${process.env.VUE_APP_API_URL}api/login/token`,
    null,
    {
      withCredentials: true
    });
  
  setAccessTokenCommon.call(this, context, response.data.accessToken);
}

export default new Vuex.Store<State>({
  state: {
    accessToken: '',
    user: null,
    silentRefreshTimeoutId: null,
    programLanding: '',
  },
  mutations: {
    SET_ACCESS_TOKEN(state, payload: string) {
      state.accessToken = payload;
    },
    SET_USER(state, payload: User | null) {
      state.user = payload;
    },
    SET_SILENT_REFRESH_TIMEOUT_ID(state, payload: number | null) {
      state.silentRefreshTimeoutId = payload;
    },
    SET_PROGAM_LANDING(state, payload: string) {
      state.programLanding = payload;
    }
  },
  actions: {
    setAccessToken(context, accessToken: string) {
      setAccessTokenCommon.call(this, context, accessToken);
    },
    async refreshAccessToken(context) {
      return await refreshAccessToken.call(this, context);
    },
    setJwtSilentRefresh(
      context,
      enable = true
    ) {
      clearTimeout(context.state.silentRefreshTimeoutId || undefined);
      context.commit('SET_SILENT_REFRESH_TIMEOUT_ID', null);

      const user = context.state.user;
      if (!enable || !user) {
        return;
      }

      let delay = user.expirationTime.valueOf() - new Date().valueOf();
      if (delay < 90000) {
        // needs to be at least a 30 second delay
        return;
      }
      delay -= 60000;

      const timeoutId = setTimeout(async () => {
        await refreshAccessToken.call(this, context);
        context.dispatch('setJwtSilentRefresh');
      }, delay);
      context.commit('SET_SILENT_REFRESH_TIMEOUT_ID', timeoutId);
    },
    setProgramLanding(context, programName: string) {
      context.commit('SET_PROGAM_LANDING', programName);
    }
  },
  getters: {
    accessToken(state) {
      return state.accessToken;
    },
    user(state) {
      return state.user;
    },
    programLanding(state) {
      return state.programLanding;
    }
  },
  modules: {
  }
})
