<template>
    <button :class="btnClass" @click="navigateToUrl">
      <slot></slot>
    </button>
  </template>
  
  <script lang="ts" setup>
  const props = defineProps({
    btnClass: {
      type: String,
      required: true
    },
    envUrlKey: {
      type: String,
      required: true
    }
  });
  
  const navigateToUrl = () => {
    if (props.envUrlKey) {
      window.open(process.env[props.envUrlKey], '_blank');
    } else {
      console.error(`Environment variable ${props.envUrlKey} not found`);
    }
  };
  </script>
  
